// 自定义字体
// import "typeface-montserrat"
// import "typeface-merriweather"
// normalize CSS across browsers

import "./src/styles/normalize.css"
// 自定义CSS样式
import "./src/styles/style.css"

// // 突出显示代码块
import "./src/styles/prism/one-dark.css"

require("prismjs/plugins/command-line/prism-command-line.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
require("prismjs/themes/prism-okaidia.css")
