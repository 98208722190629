exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commit-friend-link-js": () => import("./../../../src/pages/commit-friend-link.js" /* webpackChunkName: "component---src-pages-commit-friend-link-js" */),
  "component---src-pages-cool-album-js": () => import("./../../../src/pages/coolAlbum.js" /* webpackChunkName: "component---src-pages-cool-album-js" */),
  "component---src-pages-friend-links-js": () => import("./../../../src/pages/friend-links.js" /* webpackChunkName: "component---src-pages-friend-links-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laboratory-js": () => import("./../../../src/pages/laboratory.js" /* webpackChunkName: "component---src-pages-laboratory-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-whisper-js": () => import("./../../../src/pages/whisper.js" /* webpackChunkName: "component---src-pages-whisper-js" */),
  "component---src-templates-post-detail-js": () => import("./../../../src/templates/post-detail.js" /* webpackChunkName: "component---src-templates-post-detail-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

